import { useEffect, useRef, useState, useContext } from 'react';
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import Config from '../../../helpers/Config.js';
import { showInfoMsg } from '../../../helpers/ValidationHelper.js';
import { useSelector } from 'react-redux';
import rootAction from '../../../stateManagment/actions/rootAction.js';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, replaceLoclizationLabel, minifyJSON } from '../../../helpers/CommonHelper.js';
import { SiteBreadcrumbV2 as SiteBreadcrumb } from '../../components/layout/SiteBreadcrumb.js';
import { capitalizeFirstLetter, formatCurrency, makeAnySlugCamelCase } from '../../../helpers/ConversionHelper.js';
import PDPLoadingSkeleton from '../../components/products/PDPLoadingSekleton.js';
import { backOrderMessage } from "../../../utils/product.ts";
import { productApi, useGetAllProductsAttributesQuery, useGetHowToMeasureContentQuery, useGetProductQuery, useLazyGetColorInventoryQuery } from '../../../services/product.ts';
import { skipToken } from '@reduxjs/toolkit/dist/query/index';
import { FormGroup, Label, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import CollapsibleSections from './CollapsibleSections.js';
import { useAppDispatch } from '../../../stateManagment/reduxStore.js';
import ColorSwatches from './ColorSwatches.js';
import EmbroiderySelector from './EmbroiderySelector.js';
import ProductSizeSelector from './ProductSizeSelector.js';
import LoadingWrapper from '../../components/products/LoadingWrapper.js';
import NotFound from "../common/NotFound";
import Seo from '../../components/shared/Seo.js';
import { useAddProductMutation, useChangeQuantityMutation, useGetCartDataQuery, useLazyGetCartDataQuery } from '../../../services/cart.ts';
import LoadingScreen from '../../components/shared/LoadingScreen.js';
import { useEditLineProductMutation } from '../../../services/cart';
import { Feedback } from '../../components/feedback/Feedback.js';
import { QuantityManipulator } from './QuantityManipulator.js';
import VASTemplateV2 from '../../components/products/VASTemplateV2.js';
import ContentLoader from "react-content-loader"
import { nanoid } from '@reduxjs/toolkit';
import _ from 'lodash';
import Slider from "react-slick";
import SelectSearch from '../../components/shared/SelectSearch.js';
import PbeList from '../../components/shared/PbeList.js';
import { ToggleContext, useSwitching } from '../../../ToggleProvider.js';
import { useSwitchSessionMutation } from '../../../services/auth.ts';

const PostAddToCartModal = (props) => {
    const [LocalizationLabelsArray] = useState([]);
    const LogoImageFromStorage = useSelector(state => state.commonReducer.websiteLogoInLocalStorage);
    const siteName = useSelector(state => state.commonReducer.siteName);
    const { isToggled, setIsToggled } = useContext(ToggleContext);

    const {
        toggle,
        className,
        open,
        product,
        productActivity,
        vasData,
        showEmbroidery
    } = props;
    const productImageUrl = product?.images?.filter((item) => (productActivity?.selectedColorId ?? product?.images[0]?.colorId) === item.colorId)[0]?.url || product?.images?.[0]?.url || '/na-image.png';
    const closeBtn = <button className="close" onClick={toggle}><span className="icon icon-close"></span></button>;

    return (
        <Modal isOpen={open} toggle={toggle} className={className}>
            <ModalHeader toggle={toggle} close={closeBtn} tag='div'>
                <div className="brand d-md-none mx-auto">
                    <Link to={`/${getLanguageCodeFromSession()}/`} className="ua-logo-home logo-home">
                        <img alt="Uniform Advantage" src="https://www.uniformadvantage.com/on/demandware.static/Sites-UA-Site/-/default/dwe75547ea/images/logoIcon.svg" />
                    </Link>
                    <Link to={`/${getLanguageCodeFromSession()}/`} className="logo-home">
                        {LogoImageFromStorage ?
                            <img src={LogoImageFromStorage} alt={siteName} loading='lazy' className={siteName.replace(/\s+/g, '-').toLowerCase()} />
                            :
                            <ContentLoader
                                speed={2}
                                width={170}
                                height={38}
                                viewBox="0 0 170 38"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="170" height="38" />
                            </ContentLoader>
                        }
                    </Link>
                </div>
            </ModalHeader>
            <ModalBody>
                <h2 className="text-center mb-4 mb-md-8 add-to-cart-modal-heading">
                    {productActivity?.quantity}&nbsp;
                    {productActivity?.quantity > 1
                        ?
                        <>
                            {LocalizationLabelsArray ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Items Added to Bag", "lbl_prd_det_itemsaddedtobag")
                                :
                                "Items Added to Bag"
                            }
                        </>
                        :
                        <>
                            {LocalizationLabelsArray ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Item Added to Bag", "lbl_prd_det_itemaddedtobag")
                                :
                                "Item Added to Bag"
                            }
                        </>
                    }
                </h2>
                <div className="row">
                    <div className="col-12 col-md-8 mx-auto d-flex">
                        <div className="product-image pr-3 pr-md-6">
                            <img src={productImageUrl} alt={productImageUrl !== '/no-image.png' ? product.ProductId : 'No Image Available'} className="img-fluid" />
                        </div>
                        <div className="product-details">
                            <div className="product-header mb-5 mb-md-6">
                                {productActivity?.selectedSizeId
                                    ?
                                    <div className="product-number">
                                        {LocalizationLabelsArray ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Style", "lbl_prd_det_style")
                                            :
                                            "Style"
                                        }
                                        &nbsp;# {`${product?.id}-${productActivity?.selectedColorId ?? ''}-${productActivity?.selectedSizeId ?? ''}`.replace(/--/g, '-')}
                                    </div>
                                    :
                                    <div className="product-number">
                                        {LocalizationLabelsArray ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Style", "lbl_prd_det_style")
                                            :
                                            "Style"
                                        }
                                        &nbsp;# {product?.id}
                                    </div>
                                }
                                <h1 className="product-name">{product.name}</h1>
                            </div>
                            <ul className="list-unstyled d-flex flex-wrap">
                                {!!productActivity?.selectedColor?.name && <li className="size-attribute-title label">
                                    {LocalizationLabelsArray ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "Color", "lbl_prd_det_color")
                                        :
                                        "Color"
                                    }
                                    &nbsp;<span>{productActivity?.selectedColor?.name}</span>
                                </li>}
                                {!isToggled &&
                                    <li className="size-attribute-title label">
                                        {LocalizationLabelsArray ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_prd_det_price")
                                            :
                                            "Price"
                                        }
                                        &nbsp;<span>
                                            {formatCurrency(productActivity?.currentPrice)}
                                            &nbsp;
                                            {LocalizationLabelsArray ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "ea.", "lbl_prd_det_ea")
                                                :
                                                "ea."
                                            }
                                        </span>
                                    </li>}
                                {!!productActivity?.selectedSizeId && <li className="size-attribute-title label">
                                    {LocalizationLabelsArray ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "Size", "lbl_prd_det_sze")
                                        :
                                        "Size"
                                    }
                                    &nbsp;<span>{productActivity?.selectedSizeIdClean}</span>
                                </li>}
                                <li className="size-attribute-title label">
                                    {LocalizationLabelsArray ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "Qty", "lbl_prd_det_qty")
                                        :
                                        "Qty"
                                    }
                                    &nbsp;<span>{productActivity?.quantity}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {showEmbroidery() && productActivity?.selectedEmbroideryPresets?.length > 0 && productActivity?.selectedEmbroideryPresets[0] * 1 !== -1 &&
                        <div className="col-12 col-md-8 mx-auto mt-6">
                            <div className="EmbSelection">
                                <p className="text-black font-weight-bold mb-0">
                                    {LocalizationLabelsArray ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "Embroidery", "lbl_prd_det_embroidery")
                                        :
                                        "Embroidery"
                                    }
                                    {!isToggled ? <>
                                        &nbsp;@&nbsp;
                                        <span>{formatCurrency(product.embroideryPresets.TemplateDescriptor?.Templates[productActivity.selectedEmbroideryPresets].Price)}</span></> : <></>
                                    }
                                </p>
                                <VASTemplateV2
                                    vasData={vasData}
                                    VasSelectionIndex={0}
                                    ItemChecked={!product.embroideryPresets.TemplateDescriptor?.Optional}
                                    PreviewBaseUrl={product.embroideryPreviewBaseUrl}
                                    hexCode={productActivity.selectColorHexCode}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-center w-100">
                    <Button outline={true} color="primary" onClick={toggle} className="font-weight-bold px-0 mr-1">
                        <span className="d-md-none">
                            {LocalizationLabelsArray ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Shop more", "lbl_prd_det_continueshopping")
                                :
                                "Shop more"
                            }
                        </span>
                        <span className="d-none d-md-inline">
                            {LocalizationLabelsArray ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Continue Shopping", "lbl_prd_det_continueshopping")
                                :
                                "Continue Shopping"
                            }
                        </span>
                    </Button>
                    <Link to="/cart" className="ml-1 px-0 btn btn-primary">
                        <span className="d-md-none">
                            {LocalizationLabelsArray ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Checkout", "lbl_prd_det_proceedtocheckout")
                                :
                                "Checkout"
                            }
                        </span>
                        <span className="d-none d-md-inline">
                            {LocalizationLabelsArray ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Proceed to Checkout", "lbl_prd_det_proceedtocheckout")
                                :
                                "Proceed to Checkout"
                            }
                        </span>
                    </Link>
                </div>
            </ModalFooter>
        </Modal>
    )
}

const ProductDetail = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const embroiderySelectorRef = useRef(null);
    const [searchParams] = useSearchParams();
    const isEditMode = searchParams.get('edit') === 'true',
        editLineQty = parseInt(searchParams.get('LineQty')),
        editCartLineItemId = searchParams.get('LineItemId'),
        editSelectedEmbroideryPreset = parseInt(searchParams.get('VASTemplateId')),
        editSelectedEmployee = searchParams.get('selectedEmployee'),
        fromCartPage = searchParams.get('fromCart') === 'true';
    const qsParsed = {
        selectedSku: params.product_id,
        VASTemplateId: editSelectedEmbroideryPreset,
        VASDescriptor: searchParams.get('VASDescriptor')
    }

    const [hasAvailableSize, setHasAvailableSize] = useState(true);
    const [getCart, { isLoading: isCartLoadingLazy }] = useLazyGetCartDataQuery();
    const { isLoading: isCartLoading, data: eagerCartLoaded } = useGetCartDataQuery(null);
    const { product_id: productId, category_id: categoryId } = useParams();
    const [itemAddedToCart, setItemAddedToCart] = useState(false);
    const [sizeErrorOnAction, setSizeErrorOnAction] = useState(false);
    const [colorErrorOnAction, setColorErrorOnAction] = useState(false);
    const [embErrorOnAction, setEmbErrorOnAction] = useState(false);
    const [modalVasData, setModalVasData] = useState({});
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [isProductAvailable, setIsProductAvailable] = useState(false);
    const [isEmbDropdown, setIsEmbDropdown] = useState(false);
    const [switchSession, { isLoading }] = useSwitchSessionMutation();

    const [updateErrorMessage, setUpdateErrorMessage] = useState('');

    //--set product id and cat from url
    const productActivity = useSelector(state => state.pdpReducer);
    const currentCart = useSelector(state => state.cartReducer);
    const { data: productDetail, isLoading: isProductLoading, isFetching: isProductFetching, error: productError } = useGetProductQuery(productId && categoryId ? { productId, categoryId } : skipToken);
    const [colorInventory, setColorInventory] = useState(null);
    const [getColorInventory, colorInventoryResult] = useLazyGetColorInventoryQuery();
    const { data: productAllAttributes, isFetching: isAllProductAttributeFetching } = useGetAllProductsAttributesQuery(!isProductFetching && !isProductLoading ? productDetail : skipToken);
    const { data: howToMeasureContent } = useGetHowToMeasureContentQuery(productDetail?.id ? productDetail : skipToken);
    const [updateLineItem, { isLoading: isEditProductLoading }] = useEditLineProductMutation();
    const [addToCart, { isLoading: isAddProductLoading }] = useAddProductMutation();
    const [, { isLoading: isUpdateProductLoading }] = useChangeQuantityMutation();
    const [pbeFeedback, setPbeFeedback] = useState({
        show: false,
        message: '',
        type: ''
    });
    var [isMobile, setIsMobile] = useState(false);
    var [isDesktop, setIsDesktop] = useState(false);
    const [sticky, setSticky] = useState("");
    const [addToCartBeforeHeight, setAddToCartBeforeHeight] = useState();
    const [zoomModal, setZoomModal] = useState(false);
    const [zoomModalImages, setZoomModalImages] = useState(null);
    const [selectedCarouselIndex, setSelectedCarouselIndex] = useState(0);
    const { showSwitching, hideSwitching } = useSwitching();
    const { isToggled, setIsToggled, intentToSwitch, setIntentToSwitch } = useContext(ToggleContext);
    const [sizingTotals, setSizingTotals] = useState(null);
    const { user: { SizingTemplate: userSizingTemplate } } = useSelector((state) => state.userReducer);
    const [inlineMessage, setInlineMessage] = useState('');
    const [inlineSuccess, setInlineSuccess] = useState(true);

    const switchToSelfPay = async() => {
        setIntentToSwitch(false);
        const Email = "NOOP";
        const Password = "NOOP";
        showSwitching();
        setIsToggled(!isToggled);
        switchSession({ Email, Password })
        .unwrap()
        .catch((error) => {
            setIsToggled(!isToggled);
            window.location.href = '/';
        })
        .finally(() => {
            window.location.href = '/';
        });
    }

    useEffect(() => {
        if (isProductFetching || isCartLoading) return;

        if (isToggled && (!currentCart.products || currentCart.products?.length == 0)) {
            //this part executes ONLY when cart is empty and in sizing mode
            //Since the cart is empty at this point , go entirely by sizing quotas and find if current product class has any quota
            let currentlyAllowed = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClasses.includes(productDetail?.itemClass))?.MaxQuantityPerOrder || 0;
            if(currentlyAllowed>0){
                setInlineMessage('You have available credits for this product category - see chart above.');
                setInlineSuccess(true);
            } else {
                setInlineMessage('You have used your available credits for this product category. To add a new item, you must remove an existing item from your cart.');
                setInlineSuccess(false);
            }
        }
        if (currentCart?.products?.length > 0) {
            const groupedByCategory = currentCart?.products?.reduce((accumulator, item) => {
                const { itemClass, quantity } = item;

                let merchClassCsv = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClasses.includes(itemClass))?.MerchClassCsv ?? '';

                if (!accumulator[merchClassCsv]) {
                    accumulator[merchClassCsv] = { items: [], total: 0, allowed: 0 };
                }

                accumulator[merchClassCsv].items.push(item);
                accumulator[merchClassCsv].total += quantity;
                try {
                    accumulator[merchClassCsv].allowed = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClassCsv == merchClassCsv)?.MaxQuantityPerOrder || 0;
                } catch (error) {
                    accumulator[merchClassCsv].allowed = 0;
                }

                return accumulator;

            }, {});

            if (isToggled) {
                let updatedData = (groupedByCategory && Object.keys(groupedByCategory).length > 0) ? groupedByCategory : {};
                try {
                    for (let i = 0; i < userSizingTemplate?.SizingTemplates[0]?.Quotas?.length; i++) {
                        if (!Object.keys(updatedData).includes(userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClassCsv)) {
                            updatedData[userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClassCsv] = { items: [], total: 0, allowed: userSizingTemplate.SizingTemplates[0]?.Quotas[i].MaxQuantityPerOrder };
                        }
                    }
                    const totalAllowed =  Object.values(updatedData).reduce((acc, item) => acc + item.allowed, 0);
                    const totalAdded =  Object.values(updatedData).reduce((acc, item) => acc + item.total, 0);
                    setSizingTotals({totalAdded, totalAllowed});
                    const fromProductFitSwitch = searchParams.get('fromProductFitSwitch') === 'true';
                    if(!fromProductFitSwitch && sizingTotals && totalAdded === totalAllowed) {
                        navigate('/cart');
                    }
                    let merchClassCsv = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClasses.includes(productDetail?.itemClass)).MerchClassCsv;
                    const hasItems = updatedData[merchClassCsv] !== undefined;
                    const allowed = hasItems ? updatedData[merchClassCsv].allowed : null;
                    const total = hasItems ? updatedData[merchClassCsv].total : null;
                    if (allowed - total > 0) {
                        setInlineMessage('You have available credits for this product category - see chart above.');
                        setInlineSuccess(true);
                    } else {
                        setInlineMessage('You have used your available credits for this product category. To add a new item, you must remove an existing item from your cart.');
                        setInlineSuccess(false);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                setInlineSuccess(true);
            }
        }
    }, [currentCart, isProductFetching, isCartLoading]);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    const resetEmbErrorOnAction = () => {
        setEmbErrorOnAction(false);
    };

    const ProductZoomModal = (props) => {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
        const {
            toggle,
            open,
            selectedCarouselIndex
        } = props;
        const closeBtn = <button className="close" onClick={toggle}><span className="icon icon-close"></span></button>;

        var settings = {
            arrows: zoomModalImages?.length === 1 ? false : true,
            dots: false,
            infinite: false,
            speed: 500,
            swipe: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: selectedCarouselIndex,
            mobileFirst: true
        };

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
                if (window.innerWidth > 992) {
                    setZoomModal(false);
                }
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return (
            <Modal isOpen={open} toggle={toggle} id="mobileZoomImageCarouselModal">
                <ModalHeader toggle={toggle} close={closeBtn} tag='div'></ModalHeader>
                <ModalBody>
                    <Slider {...settings} style={{ height: (windowDimensions?.height) + 'px' }}>
                        {zoomModalImages?.map((item, index) => {
                            return (
                                <div className='zoom-slide' key={`${item.name}`}>
                                    <div id={`image-slide-${index}`} style={{ backgroundImage: `url(${item.url})` }}></div>
                                </div>
                            )
                        })}
                    </Slider>
                </ModalBody>
            </Modal>
        )
    }

    const togglePostAddToCartModal = () => {
        setItemAddedToCart(!itemAddedToCart);
    };

    const user = useSelector(state => state.userReducer.user);
    const siteInfo = useSelector(state => state.commonReducer);

    const currentPbeMapping = currentCart?.products?.map((item) => {
        var style = item.productId?.split('-')[0];
        var currentStyle = productActivity?.skuBits[0];
        if (style === currentStyle) {
            return {
                Name: item.SelectedEmployee,
                Color: item.colorName,
                ColorLongName: item.ColorLongName,
                Size: item.sizeShortName,
                Qty: item.quantity
            }
        } else return null;
    }).filter(x => !!x);

    useEffect(() => {
        if (!isCartLoading && editCartLineItemId && !isProductFetching) {
            var lineItemfoundInCart = eagerCartLoaded.products?.find(x => x.lineItemId === editCartLineItemId);
            var categoryId = productActivity?.categoryId;
            if (!lineItemfoundInCart) {
                dispatch(productApi.util.invalidateTags(['Product']));
                var productName = productDetail?.name;
                if (!_.isUndefined(productName) && !_.isNull(productName)) {
                    productName = _.kebabCase(productName);
                }
                navigate(`/${getLanguageCodeFromSession()}/product-detail/${productId}/${categoryId}/${productName}}`);
            }
        }
    }, [isCartLoading, isProductFetching, eagerCartLoaded]);

    // Get color inventory only after product is fetched
    useEffect(() => {
        if (productActivity && productActivity.productId && !isProductFetching && productActivity.selectedColorId !== null) {
            getColorInventory({ colorId: productActivity.selectedColorId, product: productDetail });
        }
    }, [isProductFetching, productActivity?.productId, productActivity?.selectedColorId]);

    // Set color inventory after fetching
    useEffect(() => {
        if (!colorInventoryResult.isFetching) {
            setColorInventory(colorInventoryResult.data);
        }
    }, [colorInventoryResult.isFetching]);

    useEffect(() => {
        if (!isAllProductAttributeFetching && productActivity.selectedColorId && productAllAttributes !== undefined) {
            const colorAttributes = productAllAttributes?.filter(patt => patt.colorId === productActivity.selectedColorId);
            const minPrice = Math.min(...colorAttributes.map(ca => ca.priceAdjustment));
            dispatch(rootAction.pdpActions.setColorBasePrice(minPrice));
        }
    }, [isAllProductAttributeFetching, productActivity.selectedColorId, productAllAttributes]);

    useEffect(() => {
        setIsProductAvailable(
            !isProductFetching && !isAllProductAttributeFetching && productDetail?.id &&
            (productDetail?.type == 'item' || productAllAttributes?.length > 0) // if product is not master/variant, ignore attributes
        );
    }, [isProductFetching, isAllProductAttributeFetching, productDetail])

    const showEmbroidery = () => {
        if (!productDetail?.embroideryEnabled || Object.keys(editVasData || {}).length === 0)
            return false;

        // if product has item classes, check if it is in the list
        // if not, return false
        if (editVasData?.TemplateDescriptor?.ItemClasses?.length > 0 && 
            !editVasData?.TemplateDescriptor?.ItemClasses.includes(productDetail?.itemClass))
            return false;

        return true;
    };

    const [editVasData, setEditVasData] = useState(null);
    // Handle product id set state to master product
    useEffect(() => {
        const productBits = productId.split('-');
        dispatch(rootAction.pdpActions.setCategoryAndProductId({ productId: productId, categoryId: categoryId }));
        if (!isProductFetching && !productError) {
            if (!productDetail.parentCategoryId && productDetail.primaryCategoryId && (categoryId === "root" || categoryId !== productDetail.primaryCategoryId)) {
                window.history.replaceState({}, document.title, `/${getLanguageCodeFromSession()}/product-detail/${productDetail.id}/${_.kebabCase(productDetail.primaryCategoryId)}/${_.kebabCase(productDetail.name)}`);
            } else if ((!categoryId || categoryId === "root" || categoryId !== productDetail.primaryCategoryId) && productDetail.primaryCategoryId) {
                window.history.replaceState({}, document.title, `/${getLanguageCodeFromSession()}/product-detail/${productDetail.id}/${_.kebabCase(productDetail.primaryCategoryId)}/${_.kebabCase(productDetail.name)}`);
            }

            const hasVariationColorAttribute = productDetail?.variationAttributes?.find((productAttribute) => productAttribute.id === 'color');
            if (productBits?.length > 1) {
                if ((productDetail?.colors.length && productDetail?.sizes.length) ||
                    (productDetail?.colors.length > 0 && !productDetail?.sizes.length && productBits?.length === 2)) {
                    let productColor = productDetail?.colors?.find(color => color.id === productBits?.[1]);
                    dispatch(rootAction.pdpActions.setActiveColor({
                        ...productColor,
                        noSize: !productDetail?.sizes?.length > 0
                    }));
                    dispatch(rootAction.pdpActions.setActiveHexCode(productDetail?.colors?.find(color => color.id === productBits?.[1])?.hexCode));
                }
                if (editLineQty && editLineQty > 0) {
                    dispatch(rootAction.pdpActions.setQuantity({ quantity: editLineQty, minQuantity: 0, maxQuantity: 99999 }));
                }
            } else {
                dispatch(rootAction.pdpActions.setActiveColor({
                    ...productDetail?.colors?.[0],
                    noSize: !productDetail?.sizes?.length > 0
                }));
                dispatch(rootAction.pdpActions.setActiveHexCode(productDetail?.colors?.[0]?.hexCode));
            }

            if (!hasVariationColorAttribute) {
                dispatch(rootAction.pdpActions.setActiveColor({
                    id: ''
                }));
            }

            if (productDetail?.id && Object.keys(productDetail?.embroideryPresets || {}).length > 0) {
                var isDropdown = isEmbDropdown;
                if (!productDetail?.embroideryPresets?.TemplateDescriptor?.Templates?.length) {
                    setIsEmbDropdown(false);
                } else {
                    var parsed = productDetail?.embroideryPresets.TemplateDescriptor?.Templates.length > siteInfo.embChoiceDisplayGreaterThan ?
                        siteInfo.embChoiceDisplayGreaterThanOverride : siteInfo.embChoiceDisplayDefault;
                    isDropdown = parsed != 1;
                    if (isEmbDropdown != isDropdown)
                        setIsEmbDropdown(isDropdown);
                }

                dispatch(rootAction.pdpActions.setActiveEmbroideryPresets(
                    [(isNaN(editSelectedEmbroideryPreset) ? null : editSelectedEmbroideryPreset) ??
                        // if select has a 'no option selected', not a valid choice, needs to be denied before add to cart
                        (!!siteInfo.embChoiceDropdownDefaultOptionText && isDropdown) ?
                        -2 :
                        // if 'no embroidery', is a valid choice
                        (productDetail?.embroideryPresets?.TemplateDescriptor?.Optional ?
                            -1 : 0)]));
                setEditVasData(productDetail.embroideryPresets);
            }

            if (!!editSelectedEmployee && editSelectedEmployee.split(',').length == 2) {
                var arr = editSelectedEmployee.split(',');
                dispatch(rootAction.pdpActions.setEmployee({
                    FirstName: arr[0],
                    LastName: arr[1]
                }));
            }

            if (productDetail?.currentFit) {
                let fitSuffix = productDetail?.currentFit !== 'REGULAR' ? productDetail?.currentFit : '';
                if (fitSuffix) {
                    dispatch(rootAction.pdpActions.setActiveFitType(productDetail.currentFit));
                }
            }
            if (qsParsed.VASDescriptor && qsParsed.selectedSku) {
                try {
                    const qVASTemplateId = qsParsed.VASTemplateId;
                    const qVASDescriptorOuter = JSON.parse(qsParsed.VASDescriptor);
                    setEditVasData({
                        ...productDetail.embroideryPresets,
                        TemplateDescriptor: {
                            ...productDetail.embroideryPresets.TemplateDescriptor,
                            Templates: productDetail.embroideryPresets.TemplateDescriptor.Templates.map((template, index) => {
                                if (index === qVASTemplateId) {
                                    return {
                                        ...template,
                                        VASData: qVASDescriptorOuter
                                    }
                                } else return template;
                            })
                        }
                    })
                } catch { }
            }

            if (productDetail?.sizes?.length === 0) {
                dispatch(rootAction.pdpActions.setCurrentPrice(productDetail?.price));
            }
        }

        return () => {
            dispatch(rootAction.pdpActions.reset());
            setSizeErrorOnAction(false);
            setColorErrorOnAction(false);
        }
    }, [productId, categoryId, dispatch, isProductFetching, editSelectedEmbroideryPreset, editLineQty, productError]);

    useEffect(() => {
        if (!productActivity?.productId || !productDetail || colorInventoryResult.isFetching || isProductFetching || isAllProductAttributeFetching)
            return;

        var message = '';
        var hasColors = productDetail.colors?.length > 0;
        var hasSizes = productDetail.sizes?.length > 0;

        const inv = colorInventory?.length === 1 ? colorInventory[0] :
            colorInventory?.find(c =>
                c.colorId === (productActivity.selectedColorId ? productActivity.selectedColorId : '') &&
                c.sizeId === (productActivity.selectedSizeId ? productActivity.selectedSizeId : ''));

        if (productActivity?.selectedColor?.id && !productActivity?.selectedColor?.isOrderable)
            message = 'This item is no longer available.'; // if selected and is not orderable
        else if ((hasColors && !productActivity?.selectedColorId) || (hasSizes && !productActivity?.selectedSizeId))
            message = 'Select Styles for Availability'; // one of the two attribute is not selected
        else if (inv) message = backOrderMessage(inv); // inventory found, let function figure it out

        dispatch(rootAction.pdpActions.setBackOrderMessage(message));

    }, [isProductFetching, colorInventory, productActivity?.selectedSizeId, productActivity?.selectedColor, productActivity?.selectedColorId])

    const updateBackOrderMessage = () => {

    };

    // Edit more from query string
    useEffect(() => {
        const qSelectedSku = qsParsed.selectedSku;
        const qVASTemplateId = qsParsed.VASTemplateId + 1;

        let qVASDescriptorOuter = [];

        if (qsParsed.VASDescriptor) {
            try {
                qVASDescriptorOuter = JSON.parse(qsParsed.VASDescriptor);
            } catch { }
        }

        //if there is a value in all 3, then this is edit mode.
        if (qSelectedSku && qVASTemplateId && qVASDescriptorOuter) {
            dispatch(rootAction.pdpActions.setActiveEmbroideryPresets([Number(qsParsed.VASTemplateId)]));
        }
    }, [dispatch, window.location.search, productDetail]);

    useEffect(() => {
        // calculate height from button to page top
        getHeight();

        // invoke this for initial render
        handleWindowResize();
        // listen for resize event and handle resolution change
        window.addEventListener("resize", handleWindowResize.bind(this));

        // onScroll added classe when addtocart button touch the top
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    });

    function getHeight() {
        let getHeight = document?.querySelector(".subInnerDetailsWrapper")?.offsetHeight +
            document?.querySelector(".leftProductWrapper")?.offsetHeight;
        setAddToCartBeforeHeight(getHeight);
    }

    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= addToCartBeforeHeight ? "add-to-cart-sticky" : "";
        setSticky(stickyClass);
    };

    const classes = `${sticky}`;

    const handleWindowResize = () => {
        const resolution = window.innerWidth;
        if (resolution < 992) {
            setIsMobile(true);
            setIsDesktop(false);
        } else {
            setIsDesktop(true);
            setIsMobile(false);
        }
    }

    const headerData = () => {
        var sku = [productDetail?.id, (productActivity?.selectedColorId ?? ''), (productActivity?.selectedSizeId ?? '')]
            .filter(Boolean).join('-');
        return (
            // common header render for desktop and mobile
            <div className="product-title-block">
                <div id="pdpTitleWrap" className="product-details-inner clearfix">
                    <div className="product-header mb-4">
                        {!!productDetail?.id
                            ?
                            <div className="product-number">STYLE # {sku}</div>
                            :
                            <div className="product-number">STYLE # {productId}</div>
                        }
                        <h1 className="product-name">{productDetail?.name}</h1>
                    </div>
                </div>
            </div >
        )
    }

    const getDisplayPrice = () => {
        if (productActivity.currentPrice == 999999 || productDetail?.price == 999999)
            return "Sold Out";

        if (productActivity.currentPrice > 0)
            return formatCurrency(productActivity.currentPrice);

        if (productDetail?.minPrice != productDetail?.maxPrice)
            return `${formatCurrency(productDetail?.minPrice)} - ${formatCurrency(productDetail?.maxPrice)}`;

        return formatCurrency(productDetail?.price || productActivity?.colorBasePrice);
    }

    const HandleUpdateEmbroidery = async () => {
        setUpdateErrorMessage('');
        var productSelectedAttributesTemp = [];
        var lineItemfoundInCart = currentCart.products?.find(x => x.lineItemId === editCartLineItemId);
        // do normal product add if no line item found
        if (!lineItemfoundInCart) {
            HandleAddToCart();
            return;
        }

        if (productActivity.hasEmptyEmbroideryText) {
            embroiderySelectorRef.current.scrollIntoView({ behavior: 'smooth' });
            dispatch(rootAction.pdpActions.setShowEmbroideryError(true));
            return;
        }

        if (productActivity.selectedColorId) {
            productSelectedAttributesTemp.push({ ProductId: params.product_id, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['Color'], PrimaryKeyValue: productActivity.selectedColorId });
        }

        if (productActivity.selectedSizeId) {
            productSelectedAttributesTemp.push({ ProductId: params.product_id, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['Size'], PrimaryKeyValue: productActivity.selectedSizeId });
        }

        //--check if size selected
        if (!productActivity.selectedSizeId && productDetail.sizes.length > 0) {
            setSizeErrorOnAction(true);
            return;
        }

        //--check if color selected
        if (!productActivity.selectedColorId && productDetail.colors.length > 0) {
            setColorErrorOnAction(true);
            return;
        }

        //--validate all others attributes except color and size because its already validated above
        let localAttributes = productAllAttributes?.filter(x => x.ProductAttributeID !== Config.PRODUCT_ATTRIBUTE_ENUM['Color'] && x.ProductAttributeID !== Config.PRODUCT_ATTRIBUTE_ENUM['Size']);
        for (let index = 0; index < localAttributes?.length; index++) {
            const elementAttr = localAttributes[index];
            if (elementAttr?.IsRequiredAttribute !== undefined && elementAttr?.IsRequiredAttribute === true) {
                if (!productSelectedAttributesTemp.some(x => x.ProductAttributeID === elementAttr.ProductAttributeID)) {
                    showInfoMsg("Please select " + elementAttr.AttributeDisplayName + " variant!");
                    return false;
                }
            }
        }

        let selectedEmbIdx = manageEmboidery(productSelectedAttributesTemp);
        if (selectedEmbIdx == -2) {
            setEmbErrorOnAction(true);
            return;
        }

        let selectedLongColorName = "No Color";

        if (productDetail && productDetail.colors && productDetail.colors.length > 0) {
            for (let o = 0; o < productDetail.colors.length; o++) {
                if (productDetail.colors[o].id === productActivity.selectedColorId) {
                    selectedLongColorName = productDetail.colors[o].name;
                    break;
                }
            }
        }

        if (user.Recipients?.length > 0) {
            if (!productActivity.selectedEmployee) {
                setPbeFeedback({
                    type: 'error',
                    message: 'As an Admin, you must select a recipient for this product before adding to Cart.',
                    show: true
                })
                return;
            }
        }

        try {
            await updateLineItem({
                lineItemId: editCartLineItemId,
                productId: productActivity.productId,
                quantity: productActivity.quantity,
                productSelectedAttributes: productSelectedAttributesTemp,
                defaultImage: productDetail?.images?.[0].url,
                CategoryId: categoryId,
                HexCode: productActivity.selectColorHexCode,
                ColorLongName: selectedLongColorName,
                SelectedEmbTemplateId: selectedEmbIdx,
                availabilityMessage: productActivity.backOrderMessage,
                selectedEmployee: productActivity.selectedEmployee,
                itemClass: productDetail?.itemClass,
            })
                .unwrap()
                .then((data) => {
                    dispatch(rootAction.cartActions.setCart(data));
                });
            //navigate back to cart
            navigate('/' + getLanguageCodeFromSession() + '/cart');
        } catch (err) {//PDP_UPDATE_EMBROIDERY_ERROR
            let regex = Config.ERROR_MESSAGES.PDP_UPDATE_EMBROIDERY_ERROR_REGEX;
            if (err.data) {
                if (regex.test(err.data.message))
                    setUpdateErrorMessage(Config.ERROR_MESSAGES.PDP_UPDATE_EMBROIDERY_ERROR);
                else setUpdateErrorMessage(err.data.message);
            }
            console.error(err);
        }
    }

    const manageEmboidery = (productSelectedAttributesTemp) => {
        let selectedEmbIdx = showEmbroidery() && isEmbDropdown && !!siteInfo.embChoiceDropdownDefaultOptionText ? -2 : -1;
        if (productActivity.selectedEmbroideryPresets.length > 0)
            selectedEmbIdx = productActivity.selectedEmbroideryPresets[0] * 1;
        //--Push the embroidery if applicable and selected
        if (productActivity.currentEmbroideryConfig) {
            selectedEmbIdx = productActivity.selectedEmbroideryPresets[0] || 0;
            if (productDetail.embroideryEnabled && selectedEmbIdx >= 0) {
                var embDescr = productDetail.embroideryPresets.TemplateDescriptor;
                var embSelected = embDescr.Templates?.[selectedEmbIdx];
                let VASDataPrepared = JSON.parse(JSON.stringify(productActivity.currentEmbroideryConfig));

                if (productActivity.customEmbroideryConfig) {
                    VASDataPrepared = VASDataPrepared.map((x, i) => {
                        if (!x.isCustom || !x.componentAttributes)
                            return x;

                        if (x.component.toLowerCase() === 'customlogo') {
                            var id = x.componentAttributes.find(y => y.componentAttribute.toLowerCase() === "logo name")?.attributeValue;
                            if (productActivity.customEmbroideryConfig[id]) {
                                // if optional and selectedIndex -1, remove the component
                                if (productActivity.customEmbroideryConfig[id].selectedIndex === -1)
                                    return null;

                                x.componentAttributes.map(y => {
                                    if (y.componentAttribute.toLowerCase() === "logo number") {
                                        y.attributeValue = productActivity.customEmbroideryConfig[id].guid;
                                        y.attributeValueDescription = productActivity.customEmbroideryConfig[id].name;
                                    }
                                    return y;
                                });
                                return x;
                            }
                        } else if (x.component.toLowerCase() === 'text') {
                            /// PENDING FUTURE DEVELOPMENT
                        }
                        return x;
                    })
                        // removes the unselected components
                        .filter(o => o !== null);
                }

                setModalVasData(VASDataPrepared);
                var embStrSelected = minifyJSON(JSON.stringify(VASDataPrepared));
                var embSelectedPrice = embSelected?.Price;
                productSelectedAttributesTemp.push({ ProductId: params.product_id, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['EmbroideryMeta'], PrimaryKeyValue: embStrSelected });
                productSelectedAttributesTemp.push({ ProductId: params.product_id, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['EmbroideryPrice'], PrimaryKeyValue: embSelectedPrice });
            }
        }
        return selectedEmbIdx;
    };

    const HandleAddToCart = async () => {
        if (isAddProductLoading) {
            // block the event if it is already adding
            return;
        }
        var productSelectedAttributesTemp = [];
        if (productActivity.maxQuantity === 0) {
            setPbeFeedback({
                type: 'error',
                message: `Only "0" items in stock. Selected quantity for "${productDetail?.name}" cannot be added to the cart.`,
                show: true
            });
            return;
        }

        if (productActivity.hasEmptyEmbroideryText || productActivity.hasEmptyCustomLogo) {
            embroiderySelectorRef.current.scrollIntoView({ behavior: 'smooth' });
            dispatch(rootAction.pdpActions.setShowEmbroideryError(true));
            return;
        }

        if (productActivity.selectedColorId) {
            productSelectedAttributesTemp.push({ ProductId: params.product_id, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['Color'], PrimaryKeyValue: productActivity.selectedColorId });
        }

        if (productActivity.selectedSizeId) {
            productSelectedAttributesTemp.push({ ProductId: params.product_id, ProductAttributeID: Config.PRODUCT_ATTRIBUTE_ENUM['Size'], PrimaryKeyValue: productActivity.selectedSizeId });
        }

        //--check if size selected
        if (!productActivity.selectedSizeId && productDetail.sizes.length > 0) {
            setSizeErrorOnAction(true);
            return;
        }

        //--check if color selected
        if (!productActivity.selectedColorId && productDetail.colors.length > 0) {
            setColorErrorOnAction(true);
            return;
        }

        //--validate all others attributes except color and size because its already validated above
        let localAttributes = productAllAttributes?.filter(x => x.ProductAttributeID !== Config.PRODUCT_ATTRIBUTE_ENUM['Color'] && x.ProductAttributeID !== Config.PRODUCT_ATTRIBUTE_ENUM['Size']);
        for (let index = 0; index < localAttributes?.length; index++) {
            const elementAttr = localAttributes[index];
            if (elementAttr?.IsRequiredAttribute !== undefined && elementAttr?.IsRequiredAttribute === true) {
                if (!productSelectedAttributesTemp.some(x => x.ProductAttributeID === elementAttr.ProductAttributeID)) {
                    showInfoMsg("Please select " + elementAttr.AttributeDisplayName + " variant!");
                    return false;
                }
            }
        }

        let selectedEmbIdx = manageEmboidery(productSelectedAttributesTemp);
        if (selectedEmbIdx == -2) {
            setEmbErrorOnAction(true);
            return;
        }

        let selectedLongColorName = "No Color";

        if (productDetail && productDetail.colors && productDetail.colors.length > 0) {
            for (let o = 0; o < productDetail.colors.length; o++) {
                if (productDetail.colors[o].id === productActivity.selectedColorId) {
                    selectedLongColorName = productDetail.colors[o].name;
                    break;
                }
            }
        }

        if (user.Recipients?.length > 0) {
            if (!productActivity.selectedEmployee) {
                setPbeFeedback({
                    type: 'error',
                    message: 'As an Admin, you must select a recipient for this product before adding to Cart.',
                    show: true
                })
                return;
            }
        }

        let recoveredCart = null;
        if (!isCartLoadingLazy && !currentCart?.id) {
            recoveredCart = await getCart(null).unwrap();
        }

        try {

            const lineItemIdFound = (!currentCart?.id && recoveredCart ? recoveredCart : currentCart).products?.find(x => {
                const embroideryChecksum = x.embroidery.map(e => e.componentAttributes.map(ca => ca.attributeValue).join('')).join('');
                const incomingEmbroideryChecksum = productActivity.currentEmbroideryConfig?.map(e => e.componentAttributes.map(ca => ca.attributeValue).join('')).join('') ?? '';
                return x.productId === productActivity.skuBits.filter(b => b !== '').join('-') &&
                    x.EmbTemplateId === Number(selectedEmbIdx) &&
                    embroideryChecksum === incomingEmbroideryChecksum &&
                    x.SelectedEmployee === productActivity.selectedEmployee
            });
            await addToCart({
                productId: productActivity.productId,
                quantity: Number(productActivity.quantity) + (Number(lineItemIdFound?.quantity) || 0),
                productSelectedAttributes: productSelectedAttributesTemp,
                defaultImage: productDetail?.images?.[0].url,
                CategoryId: categoryId,
                HexCode: productActivity.selectColorHexCode,
                ColorLongName: selectedLongColorName,
                SelectedEmbTemplateId: selectedEmbIdx,
                availabilityMessage: productActivity.backOrderMessage,
                lineItemIdFound: lineItemIdFound?.lineItemId,
                selectedEmployee: productActivity.selectedEmployee,
                itemClass: productDetail?.itemClass
            })
                .unwrap()
                .then((data) => {
                    setItemAddedToCart(true);
                    dispatch(rootAction.cartActions.setCart(data));
                })
                .catch((err) => {
                    setPbeFeedback({
                        type: 'error',
                        message: err.data.message,
                        show: true
                    });
                });
        } catch (err) {
            setPbeFeedback({
                type: 'error',
                message: 'An error occurred while adding the item to cart. Please try again later.',
                show: true
            });
        }
    }

    const handleColorChange = async (event) => {
        let color = productDetail?.colors?.find(color => color.id === event.target.value);
        dispatch(rootAction.pdpActions.setActiveColor(color));
        dispatch(rootAction.pdpActions.setActiveHexCode(color.hexCode));
        if (productDetail?.sizes?.length === 0) {
            dispatch(rootAction.pdpActions.setCurrentPrice(color.price));
        }
    }

    if (isProductLoading || isProductFetching) {
        return (<div className='text-center'>
            <PDPLoadingSkeleton />
            <br />
        </div>)
    }

    if (productError || (!isProductFetching && !productDetail.id)) {
        return <NotFound />
    }

    const toggleFeedback = () => {
        setPbeFeedback({
            type: '',
            message: '',
            show: false
        })
    }

    // inMobile onclick Zoom function called
    const ClickZoomFunc = (index, filteredImages) => {
        setZoomModal(zoomModal => !zoomModal);
        setSelectedCarouselIndex(index);
        setZoomModalImages(filteredImages);
    }

    // product slider properties config variable
    var settings = {
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        initialSlide: 0,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 50000,
                settings: "unslick"
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    return (
        <>
            {itemAddedToCart &&
                <PostAddToCartModal open={itemAddedToCart} toggle={togglePostAddToCartModal} className="modal-add-to-cart" product={productDetail} productActivity={productActivity} vasData={modalVasData} showEmbroidery={showEmbroidery}  />}
            <Seo title={`${productDetail?.name || productDetail?.seo?.title}`} description={productDetail?.seo?.description ?? "product description"} keywords={productDetail?.seo?.keywords ?? "product description"} />
            <LoadingScreen loading={isAddProductLoading || isEditProductLoading || isUpdateProductLoading || isCartLoadingLazy} />
            <SiteBreadcrumb
                stack={[
                    { url: `/${getLanguageCodeFromSession()}/all-products/${productDetail?.parentCategoryId}/${categoryId === 'root' ? productDetail?.primaryCategoryId : categoryId}`, title: (productDetail?.categoryName || makeAnySlugCamelCase(productDetail?.category)) },
                    { title: (productDetail?.name || productDetail?.seo?.title || 'Product not available') }
                ]}
            />
            <Feedback message={pbeFeedback.message} open={pbeFeedback.show} toggle={toggleFeedback} type={pbeFeedback.type} />
            <section id="product-details-page" className="products-details-area product-details pb-8 pb-md-9">
                <div id="atc-modal-root"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-7 col-12 leftProductWrapper mb-3">
                            {isMobile && headerData()}
                            <div className="product-images mx-n4 mx-md-0">
                                {(() => {
                                    const filteredImages = productDetail?.colors.length > 0 ? productDetail?.images?.filter((item) => (productActivity?.selectedColorId ?? productDetail.images[0].colorId) === item.colorId) : productDetail?.images;

                                    if (filteredImages.length === 0) {
                                        const noImage = productDetail?.images?.[0] ?? { url: '/no-image.png', name: 'no image' };
                                        return (
                                            <div className="pdp-single-image w-100">
                                                <figure className="no-image">
                                                    <img
                                                        className='img-fluid'
                                                        src={`${noImage.url}`}
                                                        alt={`${noImage.name}`}
                                                    />
                                                </figure>
                                            </div>
                                        )
                                    } else if (filteredImages.length === 1) {
                                        return (
                                            <div className="pdp-single-image w-100" onClick={isMobile ? () => ClickZoomFunc(0, filteredImages) : void (0)}>
                                                {
                                                    isMobile
                                                        ? <img className='img-fluid' src={`${filteredImages[0].url}?sw=600&sh=890&sm=fit`}></img>
                                                        : <InnerImageZoom
                                                            src={`${filteredImages[0].url}?sw=600&sh=890&sm=fit`}
                                                            zoomSrc={filteredImages[0].url}
                                                            imgAttributes={{
                                                                alt: `${productDetail?.name}`
                                                            }}
                                                        />
                                                }
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <Slider {...settings}>
                                                {filteredImages.map((item, index) => {
                                                    return (
                                                        <div className="slide" onClick={isMobile ? () => ClickZoomFunc(index, filteredImages) : void (0)} key={`${item.name}`}>
                                                            {
                                                                isMobile
                                                                    ? <img className='img-fluid' src={`${item.url}?sw=600&sh=890&sm=fit`}></img>
                                                                    : <InnerImageZoom
                                                                        src={`${item.url}?sw=600&sh=890&sm=fit`}
                                                                        zoomSrc={item.url}
                                                                        imgAttributes={{
                                                                            alt: `${productDetail?.name}`
                                                                        }}
                                                                    />
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </Slider>
                                        )
                                    }
                                })()}

                                <ProductZoomModal
                                    open={zoomModal}
                                    toggle={ClickZoomFunc}
                                    selectedCarouselIndex={selectedCarouselIndex}
                                />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-12">
                            {isDesktop && headerData()}
                            <div className="product-details-column mt-2">
                                <div className='subInnerDetailsWrapper'>
                                    {isProductAvailable &&
                                        <>
                                            {!isToggled ?
                                                <>
                                                    <div className="row no-gutters">
                                                        <div className="col">
                                                            <span className="product-price">
                                                                {getDisplayPrice()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </> : null}
                                        </>
                                    }
                                    <div className="product-fit py-2 d-flex" disabled={isEditMode}>
                                        {productDetail?.fitList?.map((item) => {
                                            const itemSuffix = item[0] !== 'R' ? item[0] : '';
                                            const productFitSwitchParam = isToggled ? '?fromProductFitSwitch=true' : '';
                                            const urlViewDetailNext = `/${getLanguageCodeFromSession()}/product-detail/${productDetail.style}${itemSuffix}/${_.kebabCase(productDetail.category) ?? "shop"}/${_.kebabCase(productDetail.name)}${productFitSwitchParam}`;
                                            return (
                                                <Link key={item.toString()} to={urlViewDetailNext} className={productDetail.currentFit === item ? 'btn btn-sm btn-outline-primary active' : 'btn btn-sm btn-outline-primary'}>
                                                    {capitalizeFirstLetter(item)}
                                                </Link>
                                            )
                                        })}
                                    </div>
                                    {isProductAvailable ?
                                        <>
                                            {productActivity.selectedColorId !== '' && productDetail?.colors.length > 0 && !isAllProductAttributeFetching &&
                                                <>
                                                    <div className="product-description py-2">
                                                        <select className="form-control custom-select"
                                                            onChange={handleColorChange}
                                                            value={productActivity.selectedColorId}
                                                            disabled={colorInventoryResult?.isFetching}
                                                        >
                                                            {productDetail?.colors?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.id} className={`${!item.isOrderable ? 'disabled' : ''}`}>{item.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <ColorSwatches colors={productDetail?.colors} hasError={colorErrorOnAction} productAttributes={productAllAttributes} hasProductSizes={productDetail?.sizes?.length > 0} />
                                                </>}

                                            <LoadingWrapper isLoading={colorInventoryResult?.isFetching}>
                                                <ProductSizeSelector
                                                    sizes={productDetail?.sizes}
                                                    hasError={sizeErrorOnAction}
                                                    initialSizeId={productId.split('-')?.[2] || productId.split('-')?.[1]}
                                                    colorInventory={colorInventory}
                                                    productVariationAttributes={productDetail?.variationAttributes}
                                                    isLoading={colorInventoryResult?.isFetching}
                                                    productAttributes={productAllAttributes}
                                                    setHasError={setSizeErrorOnAction}
                                                    IsEdit={isEditMode}
                                                    setHasAvailableSize={setHasAvailableSize} />
                                            </LoadingWrapper>

                                            {!!productDetail?.customSizingText &&
                                                <div className="clearfix" dangerouslySetInnerHTML={{ __html: productDetail?.customSizingText }}></div>
                                            }

                                            {siteInfo.isPBEEnabled && user.Recipients?.length > 0 ?
                                                <>
                                                    <div className="pbeSelection py-2">
                                                        <SelectSearch
                                                            id="pbe-selector"
                                                            label="Who is this garment for:"
                                                            placeholder="Select employee"
                                                            callback={(ind) => dispatch(rootAction.pdpActions.setEmployee(user.Recipients?.[ind] ?? {}))}
                                                            defaultValue={user?.Recipients?.map(o => `${o.FirstName},${o.LastName}`)?.indexOf(editSelectedEmployee) ?? -1}
                                                            options={user?.Recipients?.map((item, index) => {
                                                                return ({
                                                                    value: { index },
                                                                    label: `${item.LastName}, ${item.FirstName} ${(item.Address == null || item.Address.length == 0) ? '' : ('- ' + item.Address)}`
                                                                })
                                                            })} />
                                                    </div>
                                                    {/* {true ?  */}
                                                    {siteInfo.displayPBERecipientListOnPDP &&
                                                        <PbeList id="pbe-assigned-employee-list" employeeList={currentPbeMapping} fromCart={fromCartPage}></PbeList>
                                                    }


                                                </>
                                                :
                                                <></>
                                            }

                                            {
                                                showEmbroidery() &&
                                                <div ref={embroiderySelectorRef} className='py-4 EmbSelection'>
                                                    <EmbroiderySelector
                                                        embroideryPresets={editVasData}
                                                        embPreviewBaseUrl={productDetail.embroideryPreviewBaseUrl}
                                                        resetEmbError={resetEmbErrorOnAction}
                                                        isInvalid={embErrorOnAction}
                                                        isEmbDropdown={isEmbDropdown}
                                                        hexCode={productDetail?.colors?.find(c => c.id === productActivity.selectedColorId)?.hexCode} />
                                                </div>
                                            }

                                            {productActivity.backOrderMessage ?
                                                <>
                                                    <div className='availability-msg text-right'>
                                                        <small><i>{productActivity.backOrderMessage}</i></small>
                                                    </div>
                                                </>
                                                : <></>
                                            }

                                            <div className='quantity-attribute py-2'>
                                                {!colorInventoryResult.isFetching && colorInventory?.length > 0 &&
                                                    <QuantityManipulator
                                                        key={productActivity.skuBits.join('-')}
                                                        productDetail={productDetail}
                                                        initialProductActivity={productActivity}
                                                        colorInventory={colorInventory}
                                                    />
                                                }
                                            </div>
                                        </>
                                        :
                                        (!isProductFetching && !isAllProductAttributeFetching) ? <Label className='text-danger'>Product not available</Label> : <></>
                                    }
                                </div>
                                <div className={`product-action py-2 ${classes}`}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            isEditMode ? HandleUpdateEmbroidery() : HandleAddToCart();
                                        }}
                                        disabled={
                                            !inlineSuccess || !hasAvailableSize || isProductFetching || colorInventoryResult?.isFetching || isAllProductAttributeFetching || !productDetail?.id ||
                                            (productDetail?.type !== 'item' && productAllAttributes?.length === 0) // if product is not item, check attributes
                                        }
                                    >
                                        {
                                            isEditMode ? "Save Edited Item" :
                                                "Add to Bag"
                                        }
                                    </button>
                                    {inlineSuccess ? <>
                                        <div style={{ color: "darkgreen", display: isToggled ? "block" : "none" }}>
                                            {inlineMessage}
                                        </div>
                                    </> : <>
                                        <div style={{ color: "red", display: isToggled ? "block" : "none" }}>
                                            {!userSizingTemplate.HasAllotmentAccess && !userSizingTemplate.HasAllotmentReturns && siteInfo.allotmentCompleteContinueShopping ? 
                                            <>
                                                <span>{siteInfo.allotmentCompleteContinueShopping}</span> - <Link style={{ color: "red" }} onClick={switchToSelfPay}>Click Here</Link>
                                            </> 
                                            : <>{inlineMessage}</>
                                            }
                                        </div></>
                                    }
                                    {(!isUpdateProductLoading && updateErrorMessage.length > 0) ?
                                        <Label className='text-danger'>{updateErrorMessage}</Label> : <></>
                                    }
                                </div>

                                <CollapsibleSections
                                    feature={{ description: productDetail?.fullDescription, list: productDetail?.additionalInfo }}
                                    fabric={{ description: productDetail?.fabricDescription, list: productDetail?.fabricInfo }}
                                    fitSize={productDetail?.sizeChart}
                                    howToMeasureContent={howToMeasureContent?.ContentValue}
                                    isLegacySizeChart={productDetail?.isLegacySizeChart}
                                    isUAExclusive={productDetail?.isUAExclusive}
                                    colorMatchHelp={productDetail?.colorMatchHelp}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

export default ProductDetail;
