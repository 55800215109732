
import rootAction from "../../../stateManagment/actions/rootAction";
import { Alert, Button, Input, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCartDataQuery } from '../../../services/cart.ts';
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { useUpdateAllotmentPackageMutation } from '../../../services/user';
import { api } from '../../../services/api';
import { useGetContentAssetQuery } from "../../../services/content";

const SizingBoard = (props) => {
    useGetCartDataQuery();

    const {
        isMini
    } = props;

    const dispatch = useAppDispatch();
    const currentCart = useSelector((state) => state.cartReducer);
    const [displayData, setDisplayData] = useState([]);
    const { user } = useSelector((state) => state.userReducer);
    const { user: { SizingTemplate: userSizingTemplate } } = useSelector((state) => state.userReducer);
    const [isVisible, setIsVisible] = useState(true);
    const hideList = ['checkout', 'order-review', 'order-confirmation'];
    const location = useLocation();
    const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);
    const [selectedPackageIndex, setSelectedPackageIndex] = useState(-1);
    const [previousPackageIndex, setPreviousPackageIndex] = useState(-1);
    const [updateAllotmentPackage, { isLoading }] = useUpdateAllotmentPackageMutation();
    const { data: welcomeContent, isFetching: isAssetLoading } = useGetContentAssetQuery('allotment-package-welcome');
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);
    const navigate = useNavigate();

    const handleWindowResize = () => {
        const resolution = window.innerWidth;
        if (resolution < 992) {
            setIsDesktop(false);
        } else {
            setIsDesktop(true);
        }
    }

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize.bind(this));
    });

    useEffect(() => {
        //check visibility
        var bit = true;
        var urlParts = location.pathname.split('/');
        for (var i = 0; i < hideList.length; i++)
            if (urlParts.indexOf(hideList[i]) > -1)
                bit = false;
        setIsVisible(bit);
    }, [location]);
    const sortOrder = { 'Tops': 1, 'Bottoms': 2, 'Outerwear': 3 };

    useEffect(() => {
        if (user.SelectedAllotmentPackageID > 0 && userSizingTemplate?.PackageOptions?.length > 0) {
            var fndIndex = userSizingTemplate.PackageOptions.findIndex(e => e.PackageID === user.SelectedAllotmentPackageID);
            setSelectedPackageIndex(fndIndex);
        }

        // Add classes not there in cart
        const groupedByCategory = currentCart?.products?.reduce((accumulator, item) => {
            const { itemClass, quantity } = item;

            let merchClassCsv = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClasses.includes(itemClass))?.MerchClassCsv ?? '';

            if (!accumulator[merchClassCsv]) {
                accumulator[merchClassCsv] = { items: [], total: 0, allowed: 0 };
            }

            accumulator[merchClassCsv].items.push(item);
            accumulator[merchClassCsv].total += quantity;
            try {
                accumulator[merchClassCsv].allowed = userSizingTemplate?.SizingTemplates[0]?.Quotas?.find(quota => quota.MerchClassCsv == merchClassCsv)?.MaxQuantityPerOrder || 0;
            } catch (error) {
                accumulator[merchClassCsv].allowed = 0;
            }

            return accumulator;

        }, {});

        let updatedData = (groupedByCategory && Object.keys(groupedByCategory).length > 0) ? groupedByCategory : {};
        try {
            for (let i = 0; i < userSizingTemplate?.SizingTemplates[0]?.Quotas?.length; i++) {
                if (Object.keys(updatedData).includes(userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClassCsv)) {
                    updatedData[userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClassCsv].displayText = userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClassDisplayText;
                } else {
                    updatedData[userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClassCsv] = { 
                        displayText: userSizingTemplate.SizingTemplates[0]?.Quotas[i].MerchClassDisplayText,
                        items: [],
                        total: 0,
                        allowed: userSizingTemplate.SizingTemplates[0]?.Quotas[i].MaxQuantityPerOrder
                    };
                }
            }

            setDisplayData(Object.entries(updatedData));
            dispatch(rootAction.commonAction.setAllotmentData(updatedData));
        } catch (error) {
            console.log(error);
        }
    }, [currentCart, user.SizingTemplate]);

    const handlePackageChange = (e) => {
        setPreviousPackageIndex(selectedPackageIndex);
        setSelectedPackageIndex(e.target.value);

        // if none is selected, do not show warning and update customer data
        if (selectedPackageIndex === -1) {
            packageChangeCallback(e.target.value);
            return;
        }

        setIsPackageModalOpen(true);
    };

    const cancelChangeCallback = async () => {
        setSelectedPackageIndex(previousPackageIndex);
        setIsPackageModalOpen(!isPackageModalOpen);
    }

    const packageChangeCallback = async (forced) => {
        await updateAllotmentPackage({
            id: userSizingTemplate.PackageOptions[forced ?? selectedPackageIndex].PackageID,
            options: userSizingTemplate.PackageOptions
        }).unwrap().then((res) => {
            setIsPackageModalOpen(false);
            dispatch(rootAction.userAction.setSizingTemplate({
                packageId: userSizingTemplate.PackageOptions[forced ?? selectedPackageIndex].PackageID,
                sizingTemplate: { 
                    ...res.data,
                    HasAllotmentAccess: userSizingTemplate?.HasAllotmentAccess,
                    HasAllotmentReturns: userSizingTemplate?.HasAllotmentReturns
                },
            }));
            dispatch(api.util.invalidateTags(['Products']));
            
            var soleLink = localStorage.getItem('navSoleLink')
            if (soleLink && location.pathname.indexOf(soleLink) !== 0) {
                navigate(soleLink);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const togglePackageModal = () => {
        setIsPackageModalOpen(!isPackageModalOpen);
    }

    const getDisplayData = (dd) => {
        return Object.entries(dd).map(([category, data]) => (
            <tr>
                <td>{category}</td>
                <td>{data.total}</td>
                <td>{data.allowed}</td>
                <td>{data.allowed - data.total}</td>
            </tr>
        ));
    }
    //PackageOptions
    return userSizingTemplate?.HasAllotmentAccess || userSizingTemplate?.HasAllotmentReturns ?
    (
        <>
            <AllotmentPackageModal open={isPackageModalOpen} toggle={togglePackageModal} confirm={() => packageChangeCallback()} close={cancelChangeCallback}/>
            <div id="sizingBoardContainer" className={`container mb-4 mb-md-0 ${!isVisible ? 'd-none' : ''}`}>
            {userSizingTemplate?.PackageOptions?.length > 1 && selectedPackageIndex === -1 ?
            <>
                <div className={'d-flex flex-column text-center px-8 py-5'} style={{ border: '3px solid #000' }}
                    dangerouslySetInnerHTML={{ __html: welcomeContent?.ContentValue }} />
                <div className={'col-md py-2 d-flex justify-content-center align-items-center border-top-0'} style={{ border: '3px solid #000' }}>
                    <b className={'mr-4'}>Package Options:</b>
                    {!isMini && userSizingTemplate?.PackageOptions?.length > 0 &&
                        <Input className={'w-auto'} type="select" onChange={(e) => handlePackageChange(e)} value={selectedPackageIndex}>
                            <option value={-1}>Please make a selection ...</option>
                            {userSizingTemplate.PackageOptions.map((obj, index) => {
                                return (
                                    <option key={index} value={index}>{obj.Description ?? 'NO DESC'}</option>
                                )
                            })}
                        </Input>
                    }
                </div>
            </>
            :
            <>
                {!isMini && userSizingTemplate?.PackageOptions?.length > 1 && !isDesktop &&
                    <Input className="mb-2" type="select" onChange={(e) => handlePackageChange(e)} value={selectedPackageIndex}>
                        {userSizingTemplate.PackageOptions.map((obj, index) => {
                            return (
                                <option key={'allotmentOption' + index} value={index}>{obj.Description ?? 'NO DESC'}</option>
                            )
                        })}
                    </Input>
                }
                <table className={'table table-sm table-allotment'}>
                    <thead>
                        <tr>
                            <th>
                                <div className={'d-flex justify-content-between align-items-center w-100'}>
                                    Category
                                    {!isMini && userSizingTemplate?.PackageOptions?.length > 1 && isDesktop &&
                                        <Input type="select" style={{ maxWidth: '50%' }} onChange={(e) => handlePackageChange(e)} value={selectedPackageIndex}>
                                            {userSizingTemplate.PackageOptions.map((obj, index) => {
                                                return (
                                                    <option key={'allotmentOption' + index} value={index}>{obj.Description ?? 'NO DESC'}</option>
                                                )
                                            })}
                                        </Input>
                                    }
                                </div>
                            </th>
                            <th>In Cart</th>
                            <th>Credits</th>
                            <th>Remaining</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayData?.sort((a, b) => {
                            return (sortOrder[a[0]] - sortOrder[b[0]]) || a[0].localeCompare(b[0]);
                        }).map(([category, data]) => (
                            <tr key={category}>
                                <td>{data.displayText}</td>
                                <td>{data.total}</td>
                                <td>{data.allowed}</td>
                                <td>{data.allowed - data.total}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
            }
            </div>
        </>
    ) : (<></>);
}

const AllotmentPackageModal = (props) => {

    const {
        toggle,
        open,
        confirm,
        close
    } = props;
    const { data: warningContentAsset, isFetching: isAssetLoading } = useGetContentAssetQuery('allotment-package-warning');
    const closeBtn = <button className="close" onClick={close}><span className="icon icon-close"></span></button>;

    return (
        <Modal className={'modal-reset-allotment'} isOpen={open}>
            <ModalHeader toggle={toggle} close={closeBtn} />
            <ModalBody className={'text-center'}>
                <div dangerouslySetInnerHTML={{ __html: warningContentAsset?.ContentValue }} />
            </ModalBody>
            <ModalFooter className="d-flex flex-column flex-md-row justify-content-center">
                <Button color="secondary" outline className="text-uppercase py-2" onClick={close}>
                    Cancel
                </Button>
                <Button color="secondary" className="py-2" onClick={confirm}>
                    Proceed &amp; Reset Cart
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default SizingBoard;
